import * as React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Box } from '@mui/system';
import { Link, Typography } from '@mui/material';

type CatalogueCategoryProps = {
  href?: string;
  name: string;
  image: IGatsbyImageData;
}

export default function CatalogueItem({ href, name, image }: CatalogueCategoryProps): JSX.Element {
  const imageAndText = <>
      <GatsbyImage
        image={image}
        alt=""
        loading="eager"
      />
      <Box>
        <Typography variant="body1" component="h2" mt={2}>
          {name}
        </Typography>
      </Box>
    </>;
  const content = href
    ? <Link href={href} underline="hover">{imageAndText}</Link>
    : <Box>{imageAndText}</Box>;
  return (
    <Box
      flexBasis={["100%", "50%", "33.3%"]}
      mt={["1rem", "0.5rem"]}
      mb={["2rem", "1.5rem"]}
      textAlign="center"
    >
      {content}
    </Box>
  );
}
