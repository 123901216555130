import * as React from 'react';
import Layout from '../../components/Layout';
import { graphql } from 'gatsby';
import { Container, Stack, Box } from '@mui/material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CatalogueCategory from '../../components/CatalogueCategory';

type CatalogueProps = {
  data: GatsbyTypes.PrimaryCategoriesQuery;
}

export default function Catalogue({ data }: CatalogueProps): JSX.Element {
  return (
    <Layout title="Catalogue | Oclee &amp; Son">
      <Stack minHeight="100vh">
        <Header page="catalogue" />
        <Box flex={1}>
          <Container maxWidth="md">
            <Box display="flex" flexWrap="wrap">
              {
                data.allStrapiCategories.nodes.map(n => {
                  return <CatalogueCategory
                    key={n.strapiId}
                    href={n.name != 'Oclee & Son Ltd' ? `/catalogue/category/${n.strapiId}` : undefined}
                    name={n.name != 'Oclee & Son Ltd' ? n.name : ''}
                    image={n.image.localFile.childImageSharp?.gatsbyImageData}
                  />;
                })
              }
            </Box>
          </Container>
        </Box>
        <Footer />
      </Stack>
    </Layout>
  );
}

export const query = graphql`
query PrimaryCategories {
  allStrapiCategories(
    filter: {
      parent_category: {id: {eq: null}},
      sort_order: {gte: 0}
    }
    sort: {fields: sort_order}
  ) {
    nodes {
      strapiId
      name
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              height: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              quality: 100
            )
          }
        }
      }
    }
  }
}
`;
